.product-service {
    padding: 0 25px; 
    border-bottom: 2px solid $border-color;

    .header-fiche-product, 
    .description,
    .download-pdf {
        padding: 20px 0;
    }

    .header-fiche-product {
        border-bottom: 1px solid $border-color;
    }

    .download-pdf-wrapper {
        border-top: 1px solid $border-color;
        padding-top: 10px;
    }

    .header-fiche-product {
        .header-left {
            padding: 10px 15px;
    
            .category {
                font-size: 0.95rem;
                .cat-sub {
                    &::before {
                        // content: "\f061";
                        display: inline-block;
                        margin-right: 5px;
                        color: $text-color;
                        font-weight: bold;
                    } 
                }
            }

            h1 {
                font-size: 2em;
                margin-bottom: 1rem;
            }

            .range-product {
                margin-top: 1rem;
                font-size: 1rem;
                color: $text-color;
                font-weight: bold;
            }

            h2 {
                font-size: 1.25rem;
            }
    
            .contact-supplier {
                border-radius: 0;
                color: $white;
                padding: 10px 15px;
            }
        }
    
        .header-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 10px 15px;
    
            @media (max-width: $screen-sm-max) {
                align-items: flex-start;
            }
    
            .img-supplier-wrapper {
                max-height: 115px;
                width: auto;
                max-width: 350px;
                margin-bottom: 65px;

                @media (max-width: $screen-sm-max) {
                    margin-bottom: 5px;
                }
    
                img {
                    height: 100%;
                    max-height: 100px;
                    width: auto;
                }
            }
    
            .social {
                @media (max-width: $screen-sm-max) {
                    .share-rs {
                        text-align: left !important;
                    }  
                }
            }
    
        }

    }

    .description {
        @include media-breakpoint-down(md) {
            .description-text {
                margin-bottom: 20px;
            }
        }

        .slider {
            .block-slider {
                .owl-carousel {
                    max-height: 400px;
                    // &.slider-main {
                    //     @include media-breakpoint-down(lg){
                    //         height: 356px !important;
                    //     }
                        
                    //     @media (max-width: 412px) {
                    //         height: 300px !important;
                    //     }
                    // }

                    figure > img {
                        max-height: 400px;
                        object-fit: contain;
                    }

                    .owl-nav {
                        .owl-prev, 
                        .owl-next {
                            background-color: rgba($bg-footer, 0.6);

                            &:hover {
                                background-color: rgba($white, 0.6);
                            }
                        }
                    }
                }
            }
        }
    } 
}
.fiche-fournisseur {
    padding: 0 25px;

    .header-fiche-fournisseur,
    .presentation-supplier,
    .contact-supplier,
    .company-info,
    .product-card {
        border-bottom: 1px solid $border-color;
        padding: 20px 0;
    }

    h3 {
        margin-bottom: 10px;
        font-size: 1.25em;
        font-weight: 700;
    }


    .header-fiche-fournisseur {
        .header-left {
            padding: 10px 15px;

            h1 {
                // font-weight: 700;
                font-size: 2em;
            }

            .supplier-website {
                margin-bottom: 55px;
                font-size: 15px;
                font-weight: bold;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 35px;
                }
            }

            .contact-supplier {
                border-radius: 0;
                color: $white;
                padding: 10px 15px;
            }
        }

        .header-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 10px 15px;

            @media (max-width: $screen-sm-max) {
                align-items: flex-start;
            }

            .img-supplier-wrapper {
                max-height: 115px;
                width: auto;
                max-width: 350px;

                img {
                    height: 100%;
                    max-height: 100px;
                    width: auto;
                }
            }

            .social {
                @media (max-width: $screen-sm-max) {
                    .share-rs {
                        text-align: left !important;
                    }  
                }
            }

        }
    }

    .presentation-supplier {
        .presentation,
        .reference-customer {
            margin-top: 20px;
        }
    }

    .contact-supplier, 
    .company-info {
        .label {
            font-weight: 700;
            color: $text-color;
        }
    }
}
/* Custom theme below */

$primary-color:             #BD2E1D;
// $secondary-color:           #BDBDBD;
$secondary-color:           #BD2E1D;
$text-color:                #131313;
$background-color:          #ffffff;

$light-color:               #e8ebf0;
$light-gray:                #dbdbdb;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "dark": $text-color,
    "light": $light-color,
    "light-gray": $light-gray,
    "background": $background-color,
)
.all-products-services {

    .selected-categories {
        select {
            option {
                @include media-breakpoint-down(sm) {
                    font-size: 0.85rem;
                }
            }
        }
    }
    
    .search-product {
        .z-0 {
            z-index: 0;
        }
        .input-group-append {
            z-index: 1;
        }
    }

    .result-products {
        .card-product {
            border-left: none;
            border-right: none;

            img {
                max-height: 200px;
            }

            .card-body {
                padding: 5px 15px;
                
                @include media-breakpoint-down(md) {
                    padding: 1.25rem;
                }

                .designation {
                    a {
                        &:hover,
                        &:active {
                            color: $primary-color !important;
                        }
                    }
                }

                .range-product {
                    color: $text-color;
                    font-weight: bold;
                }

                .supplier {
                    font-size: 1.25rem;
                }
            }

        }
    }
}
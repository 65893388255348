.catalog-lens{
  font-family: $font-sans;

  .header {
    margin-bottom: $grid-gutter-width * 2;
  }
}

.catalog-content-wrapper {
  border: 1px solid $border-color;
  font-family: $font-sans;
  overflow: hidden;
  padding: 2em;

  p.mb-4 {
    align-self: flex-start;
  }

  label {
      text-align: right;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
  }

  .product-name {
    margin-top: 0;
    font-size: 1.5em;
  }
  .form-control {
      height: 35px;
      padding: 6px 10px;
  }
  ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
          margin: $grid-gutter-width/2 0;
          a {
              color: $text-color;
          }
      }
  }
  a.btn-print {
      color: $text-color;
      border: 1px solid;
      display: inline-block;
      font-family: $font-sans;
      margin: $grid-gutter-width 0;
      padding: 8px 18px;

      i.fa {
          margin-right: 10px;
          @include transition(none);
      }

      &:hover {
          text-decoration: none;
          background: $text-color;
          color: $white;
      }
  }

  em {
      font-weight: 500;
  }

  dt {
      font-weight: 400;
      color: $brand-color-2;
      text-align: right;
  }

  @media (min-width:$screen-sm-min) {
    dt {
      text-align: left;
    }
  }

  .form-action .btn-color3 {
      background-color: $light-color !important;
      border-color: $light-color !important;
      color: $text-color;

      &:hover {
          background-color: darken($light-color, 5%);
      }
  }
}

// catalog
.catalog {
  font-family: $font-sans;
  .catalog-title {
        line-height: 100px;
        font-weight: 100 !important;

        @include media-breakpoint-up(md) {
            font-size: 2.75rem !important;
        }
  }
  img {
      margin: 0 auto;
      max-height: 320px;
  }
  .edito {
      margin-top: 1em;
      margin-bottom: 1em;
  }

  .catalog-summary {
      margin: 2em 0;
      header {
          &:before {
              border-color: $brand-color !important;
              margin-left: 10px !important;
          }
          h2 {
              color: $brand-color;
              margin-left: 1.5em !important;
          }
      }

      .catalog-rubric-item {
          border-left: 6px solid $brand-color;
          &.border-color1 {
              border-color: #772161;
          }
      }
  }
}

.doc-item {
  margin: 1em 0;
  h3 {
      padding: .5em 1em;
      font-family: $font-serif;
      background: #EFF0F2;
      font-size: 1.25em;
      margin: 0;
      display: flex;
      align-items: center;
  }

  .list-article {
      background: #F7F8FA;
      padding: 1em;

      li {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          margin-bottom: 10px;

          &:before {
              font-family: 'FontAwesome';
              content: "\f178";
              color: $brand-color;
              margin-right: 1em;
              border-right: 1px solid #d2d2d2;
              padding-right: 10px;
          }
          a {
              color: $text-color;
          }
      }
  }
}

.catalog-list {
  font-family: $font-sans;
  li {
      margin-top: 1em;
      padding: 0;
      img {
          margin: 0 auto;
      }
      .catalog-item-info {
          padding: 2em;
          h3 {
              font-family: $font-serif;
              margin-bottom: .5em;
              &.border-bottom:after {
                  content: "";
                  height: 1px;
                  width: 75pt;
                  background: $text-color;
                  display: block;
                  margin-top: 14px;
              }
          }
          .shortcut {
              margin: .5em 0;
              font-weight: bold;
          }
          .download-pdf {
              float: right;
              i {
                  margin-right: 5px;
              }
          }
      }
  }
}

.catalog-search {
  font-family: $font-sans;
  margin-bottom: 2em;
  label {
      margin-right: 20px;
  }
  .form-group {
      position: relative;
      label {
          font-weight: 400;
          color: $brand-color-2;
          margin-left: 10px;
          margin-right: 10px;
      }
      input {
          padding-right: 40px;
      }
      i {
          position: absolute;
          right: 14px;
          top: 1pc;
      }
  }
}

@media (min-width:$screen-md-min) {
    .row.flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        .fix-height {
            height: 100%;
            .tag-onsale {
                margin: 0 5%;
                position: absolute;
            }
            .button-wrapper {
                margin: 0;
                position: absolute;
                bottom: 50px;
                left: 0;
                right: 0;
            }
            .details-wrapper {
                ul {
                    margin-bottom: 110px;
                }
            }
        }
    }
}
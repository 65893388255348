.dowload-pdf-block {
    .pdf-icon {
        width: 50px;
    }

    .file-pdf {
        .link-file {
            color: $text-color !important;
            font-size: 1.25rem;

            &:hover {
                color: $primary-color !important;
            }

            @include media-breakpoint_down(sm) {
                font-size: 1rem;
            }

            @media (max-width: 412px) {
                font-size: 0.85rem;
            }

            @media (max-width: 358px) {
                font-size: 0.75rem;
            }

            @media (max-width: 325px) {
                font-size: 0.6rem;
                font-weight: bold;
            }
        }
    }


}
.annuaire-fournisseur {
    padding: 0 25px;

    h1 {
        font-weight: 100;
        
        @include media-breakpoint-up(md) {
            font-size: 2.75rem;
        }
    }

    .navigation-filter {
        ul {
            padding-right: 0;

            .nav-item {
                display: flex;
                justify-content: center;
                width: calc(100% / 27);
                border: thin solid rgba($light-gray, 0.7);
                white-space: nowrap;
                font-size: 18px;

                @media (max-width: $screen-sm-max) {
                    font-size: 14px;
                }

                @media (max-width: $screen-xs-max) {
                    width: calc(100% / 9);
                    font-size: 12px;
                }

                .nav-link {
                    color: $primary-color;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    cursor: pointer;
                    transition: color .15s ease-in-out,
                                background-color .15s ease-in-out,
                                border-color .15s ease-in-out,
                                box-shadow .15s ease-in-out;

                    &.active {
                        background-color: $primary-color;
                        color: $white;
                    }
                    
                    &:hover {
                        color: $white;
                        background-color: #a7291a;
                    }
                }
            }

        }
    }

    .announcer-list {
        .card {
            width: 24%;
            min-height: 285px;
            padding-top: 25px;
            border-radius: 0;

            @media (max-width: $screen-sm-max) {
                width: 49%;
            }

            @media (max-width: $screen-xs-max) {
                width: 100%;
            }

            .card-body {

                h2 {
                    font-size: 1.25em;
                    font-weight: 700;
                    margin-bottom: 10px;
                    color: $bg-footer-copyright !important;
                }

                .activity, address {
                    font-size: 14px;
                }

                .activity {
                    margin-bottom: 10px;
                }

                a[role="button"]{
                    border-radius: 0;
                    display: block;
                    width: 58%;
                    margin: auto;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                        width: 75%;
                    }

                    @media (max-width: $screen-sm-max) {
                        width: 45%;
                    }

                    @media (max-width: 414px) {
                        width: 58%;
                    }

                    @media (max-width: 335px) {
                        width: 80%;
                    }
                }
            }

        }

        .card:has(a):hover{
            background-color: rgba($menu-bg, 0.5);
        }
    }

    .pagination-wrapper {
        nav {
            .pagination {
                border: none;

                .page-item {

                    .page-link {
                        color: $primary-color;
                        transition: color .15s ease-in-out,
                                background-color .15s ease-in-out,
                                border-color .15s ease-in-out,
                                box-shadow .15s ease-in-out;

                        @include media-breakpoint-down(sm) {
                            width: 2.25rem;
                            height: 2.25rem;
                            line-height: 2.25rem;
                        }

                        &.is-not-label {
                            &:hover {
                                background-color: #a7291a;
                                border: $primary-color;
                                color: $white;
                            }
                        }

                    }

                    &.active {
                        .page-link {
                            background-color: $primary-color;
                            border: $primary-color;
                            color: $white;
                        }
                    }

                    &[aria-label="Previous"],
                    &[aria-label="Next"] {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}
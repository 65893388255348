.all-products-suppliers {
    .product-card {
        border-bottom: 1px solid $border-color;
        padding: 20px 0;

        h3, h4 {
            color: $text-color;
        }
    
        h4 {
            font-size: 0.85rem;
            font-weight: 600;
        }
    }
    
        @media (max-width: $screen-xs-max) {
            h3 {
                margin-top: 1rem;
            }
    
            h4 {
                margin-top: 0.75rem;
        }    
    }

}
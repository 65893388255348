.contact-supplier {

   .form-supplier {

        .error-form-message,
        .confirmation-form-message {
            text-align: center;

            @include media-breakpoint-down(xs) {
                text-align: left; 
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            accent-color: $primary-color;
            -ms-accent-color: $primary-color;
            -o-accent-color: $primary-color;
            -webkit-accent-color: $primary-color;
        }

        input[type="submit"] {
            border-radius: 0;
        }

        label:not(.gdpr) {
            text-align: right;
            padding-right: 4px;
            
            @include media-breakpoint-down(xs) {
                text-align: left; 
            }
        }

        label, 
        .civil-item span {
            color: $text-color;
        }

        .has-error {
            border: 1px solid $primary-color;
        }
    } 
}
//Mon compte
.connected-home {
  .account-block {
    font-family: $font-sans;
    overflow: hidden;
    margin: 20px auto 60px;

    a {
      &, &:hover {
        text-decoration: none;
      }
    }

    .account-block-item {
      padding: 5px 30px;
      background: $brand-color-2;
      margin: 20px;
      cursor: pointer;
      @include transition(all 1s ease-out);
      .title {
        h2 {
          color: $white !important;
        }
        &:before {
          border-color: $white;
        }
      }

      i{
        &.fa {
          font-size: 3em;
          color: $white;
          padding-top: 40px;
          padding-bottom: 70px;
          display: block;
          margin: 0 auto;
          text-align: center;
        }

        &.icon_pen {
          width: 48px;
        }
      }

      &:hover {
        background: $brand-color;
      }
    }
  }
}

.account-info {
  font-family: $font-sans;
  overflow: hidden;
  margin: 20px auto 60px;

  @media (min-width:$screen-md-min) {
    .col-md-6 {
      padding: 0 2%;
    }
  }

  .form-group {
    &.active:after {
      content: "\f00c";
      font-family: 'FontAwesome';
      display: inline-block;
      margin-left: 10px;
      color: #00AE2E;
    }
    i {
      margin-right: 5px;
    }
    input {
      height: 35px;
      box-shadow: none;
      outline: none;
    }

    .author-photo {
      height: 80px;
      i {
        margin: 0;
        background: #ddd;
        padding: 40px;
      }
    }

    .author-photo-default {
      i {
        font-size: 50px;
      }
    }

    .label-author-photo {
      margin-top: 16px;
    }
  }

  .label {
    font-weight: 400;
    color: $brand-color-2;
    text-align: right;
  }

  @media (min-width:$screen-sm-min) {
    .dl-horizontal {
      dt {
        white-space: normal;
        width: 45%;
      }
      dd {
        margin-left: 50%;
      }
    }
  }

  .btn.btn-md {
    padding: 12px 50px;
  }

  .cloud-liner {
    margin: 30px 0;
  }

  .personal {
    margin: 40px 0;

    form {
      .row {
        margin-top: 1em;
      }
    }
  }

  .account {
    padding: 40px;
    border: 1px solid $border-color;
    h3 {
      text-align: center;
    }

    .upload {
      a {
        text-decoration: underline;
      }
      i {
        color: $brand-color;
        margin-left: 10px;
      }
    }

    form {
      .row {
        margin-top: 1em;
      }
    }
  }
}

.subscription-block {
  font-family: $font-sans;
  overflow: hidden;
  margin: 20px auto 60px;

  table {
    font-family: $font-sans;
    font-size: .857em;
    margin-bottom: 20px;
    min-width: 100%;
    th {
      padding: 16px 5px;
      background: $brand-color-2;
      color: #fff;
      font-weight: 500;
    }
    &.table-striped tr {
      background: $menu-bg;
      &:nth-of-type(odd) {
        background: #F7F8FA;
      }
      td {
        padding: 18px 5px;
        text-align: center;
        color: #677693;
        i {
          display: block;
          text-align: center;
          font-weight: bold;
          &.fa-check {
            color: #00AE2E;
          }
          &.fa-times {
            color: #C1102B;
          }
        }
        .btn-sm {
          padding: 5px 10px;
          font-weight: normal !important;
        }
      }
    }
  }
}

.bookmark-block {
  font-family: $font-sans;
  overflow: hidden;
  margin: 20px auto 60px;
  .autoblock-vertical {
    .row {
      padding: 16px;
      border-bottom: 1px solid $border-color;

      .metadata-wrapper {
        font-size: 1em;
        &:after {
          display: none;
        }
        a {
          color: $gray-dark;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      a.delete {
        font-family: $font-sans;
        color: $brand-color;
      }

      .abstract a {
        white-space: nowrap;
      }

      h3 {
        margin-bottom: 12px;
        position: relative;
      }
    }
  }
}

.contribution-block {
  .autoblock-vertical {
    .row {
      padding: 16px;
      border-bottom: 1px solid $border-color;
    }

    .metadata-wrapper:after {
      display: none;
    }
  }

  .new-article-wrapper {
    padding: 30px;
    border-radius: 3px;
    margin: 30px;
    margin-right: 0;
    .title {
      margin: 0 auto;
      width: 200px;
      text-align: left;
      &:before {
        border-color: $white;
      }
    }

    i.icon_pen {
      width: 50px;
      margin: 20px auto;
    }
  }
}

.alert-block {
  font-family: $font-sans;
  overflow: hidden;
  margin: 20px auto 60px;

  h3 {
    margin-bottom: 30px;
  }

  em {
    min-height: 50px;
    display: block;
  }

  .block-newsletter {
    padding: 40px 30px;
    .group-switch-inner:before {
      content: "Inscrit";
      padding-left: 24px;
    }
    .group-switch-inner:after {
      content: "Non-inscrit";
      padding-right: 10px;
    }
  }

  .block-notification {
    padding: 40px 30px;
    .group-switch-inner:before {
      content: "Activé";
      padding-left: 30px;
    }
    .group-switch-inner:after {
      content: "Désactivé";
      padding-right: 16px;
    }

    .dropdown .multiSel {
      margin: 0;
    }

    .dropdown dd,
    .dropdown dt {
      margin: 0px;
      padding: 0px;
    }

    .dropdown ul {
      margin: -1px 0 0 0;
    }

    .dropdown dd {
      position: relative;
      ul {
        background-color: $white;
        border: 1px solid $border-color;
        display: none;
        right: 0px;
        padding: 2px 15px 2px 5px;
        position: absolute;
        width: 100%;
        list-style: none;
        max-height: 200px;
        overflow: auto;

        li {
          padding: 0 10px;
          label {
            margin-left: 10px;
            font-weight: normal;
          }
        }
      }
    }

    .dropdown dt a {
      display: block;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
      .placeholder-text {
        .caret {
          float: right;
          margin-top: 10px;
        }
      }
    }

    .multiSel span {
      cursor: pointer;
      display: inline-block;
      padding: 0 3px 2px 0;
    }

    .dropdown span.value {
      display: none;
    }
  }

}

.author-info-wrapper {
  padding: 30px;
  border-radius: 3px;
  margin: 30px;
  margin-right: 0;
  p {
    font-family: $font-sans;
  }

  .is-h3 {
    margin-bottom: 20px;
  }
  .is-label {
    color: $brand-color-2;
    margin-top: 10px;
  }

  .author-photo {
    background: $menu-bg;
    border: 1px solid $border-color;
    color: $brand-color-2;
    padding: 20px;
    i {
      font-size: 3em;
      display: block;
    }
  }

  @media (min-width:$screen-md-min) {
    .col-md-8 {
      padding-right: 0%;
      padding-left: 5%;
    }
  }
}

.author {
    p {
      font-family: $font-sans;
    }
    .title {
      margin: 40px 0;
    }
    .author-article {
      p {
        font-family: $font-sans;
      }

      .metadata-wrapper {
        font-size: 1em;
        [class*=metadata-] {
            font-family: $font-serif;
            font-style: italic;
        }
      }

      .row {
        padding: 16px;
        border-bottom: 1px solid $border-color;
        .metadata-wrapper {
          font-size: 1em;
          [class*=metadata-] {
              font-family: $font-serif;
              font-style: italic;
          }
        }
      }
    }
    .content.button a {
        font-family: $font-sans;
    }
}

.my-account {
    margin-bottom: 60px;
    overflow: hidden;
    font-family: $font-sans;
    &.connection .row {
        margin-bottom: 20px;
    }
}